<template lang="pug">
.update
  invoice-form(
    v-if="state.invoice"
    :invoice="state.invoice"
    @submit="submit"
  )
</template>

<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import InvoiceForm from '@/components/InvoiceForm'
import store from '@/store'

export default {
  components: { InvoiceForm },

  setup (props, ctx) {
    const { back, currentRoute } = useRouter()
    const { params } = currentRoute.value

    const { invoices } = store.state

    const findInvoice = it => it._id === params.invoice

    const state = reactive({
      invoice: invoices.find(findInvoice)
    })

    if (invoices.length === 0) {
      store.dispatch('loadInvoices').then(invoices => {
        state.invoice = invoices.find(findInvoice)
      })
    }

    const submit = async data => {
      await store.dispatch('updateInvoice', data)
      back()
    }

    return {
      state,
      submit
    }
  }
}
</script>
