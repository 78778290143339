<template lang="pug">
.invoice-form
  form
    input.invoice-form__name(
      type="text"
      placeholder="Title"
      v-model="state.title"
    )

    input.invoice-form__date(
      type="date"
      v-model="state.date"
    )

    textarea.invoice-form__header(
      placeholder="Header"
      v-model="state.header"
    )

    .invoice-form__row(
      v-for="(row, index) in state.rows"
    )
      .invoice-form__row-name
        input(
          placeholder="Row name"
          v-model="row.name"
        )

        button.invoice-form__delete(
          @click.prevent="removeRow(state.rows, index)"
        ) -

      .invoice-form__columns
        .invoice-form__column(
          v-for="(column, index) in row.columns"
        )
          input(
            placeholder="Value",
            v-model="column.value"
          )
          button.invoice-form__delete(
            @click.prevent="removeColumn(row, index)"
          ) -

      button.invoice-form__add(
        @click.prevent="addColumn(row)"
      ) Add column

      .invoice-form__row(
        v-for="(subRow, index) in row.subRows || []"
      )
        .invoice-form__row-name
          input(
            placeholder="Row name"
            v-model="subRow.name"
          )

          button.invoice-form__delete(
            @click.prevent="removeRow(row.subRows, index)"
          ) -

        .invoice-form__columns
          .invoice-form__column(
            v-for="(column, index) in subRow.columns || []"
          )
            input(
              placeholder="Value",
              v-model="column.value"
            )
            button.invoice-form__delete(
              @click.prevent="removeColumn(subRow, index)"
            ) -

        button.invoice-form__add(
          @click.prevent="addColumn(subRow)"
        ) Add column

      button.invoice-form__add(
        @click.prevent="createSubRow(row)"
      ) Add sub row

    button.invoice-form__add(
      @click.prevent="createRow"
    ) Create Row

    textarea.invoice-form__footer(
      placeholder="Footer"
      v-model="state.footer"
    )

    button.invoice-form__upload(
      type="submit"
      @click.prevent="submit"
    ) Submit
</template>

<script>
import { reactive } from 'vue'

export default {
  props: {
    invoice: {
      type: Object,
      default: () => {}
    }
  },

  setup (props, { emit }) {
    const state = reactive({
      title: '',
      date: '',
      header: '',
      footer: '',
      rows: [],
      ...props.invoice
    })

    const createRow = () => {
      state.rows.push({
        name: '',
        columns: [],
        subRows: []
      })
    }

    const createSubRow = row => {
      if (!row.subRows) row.subRows = []

      row.subRows.push({
        name: '',
        columns: []
      })
    }

    const removeRow = (rows, index) => {
      if (!rows) return
      rows.splice(index, 1)
    }

    const addColumn = row => {
      row.columns.push({ value: '' })
    }

    const removeColumn = (row, index) => {
      row.columns.splice(index, 1)
    }

    const submit = async () => {
      if (state.title) {
        emit('submit', state)
      }
    }

    return {
      state,
      createRow,
      createSubRow,
      removeRow,
      addColumn,
      removeColumn,
      submit
    }
  }
}
</script>

<style lang="scss">
.invoice-form {
  padding: 2rem 0;

  * {
    font-size: 1.5rem;
  }

  form {
    width: 70vw;
  }

  input,
  textarea {
    border: none;
    background-color: lightgray;
    padding: 0.5rem;
  }

  textarea {
    min-height: 40vh;
    max-width: 70vw;
  }

  button {
    border: none;
    padding: 0.5rem;
    background-color: white;
    text-align: left;
    cursor: pointer;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: grey;
    }
  }

  &__row {
    box-sizing: border-box;
    background-color: rgb(240, 240, 240);
    padding: 1rem;
    width: 100%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  }

  form,
  &__row {
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  &__date,
  &__header,
  &__footer,
  &__row,
  &__add,
  &__columns,
  &__upload {
    margin-top: 1rem;
  }

  &__delete {
    border: none;
  }

  &__row-name {
    display: flex;

    input {
      flex: 1 1 auto;
      width: 10%;
    }
  }

  &__columns {
    display: flex;

    input {
      flex: 1 1 auto;
      width: 100%;
    }
  }

  &__column {
    display: flex;
    flex: 1 1 auto;

    & + & {
      margin-left: 0.5rem;
    }

    input {
      flex: 1 1 auto;
      width: 10%;
    }
  }
}
</style>
