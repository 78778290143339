<template lang="pug">
.create
  invoice-form(
    @submit="submit"
  )
</template>

<script>
import InvoiceForm from '@/components/InvoiceForm'
import { useRouter } from 'vue-router'
import store from '@/store'

export default {
  components: { InvoiceForm },

  setup () {
    const { back } = useRouter()

    const submit = async data => {
      await store.dispatch('createInvoice', data)
      back()
    }

    return {
      submit
    }
  }
}
</script>
