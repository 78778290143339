<template lang="pug">
.invoice
  iframe(
    v-if="state.pdf"
    :src="state.url"
  )
</template>

<script>
import { reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store'
export default {
  setup () {
    const { currentRoute } = useRouter()

    const state = reactive({
      pdf: '',
      url: computed(() => `data:application/pdf;base64,${state.pdf}`)
    })

    store.dispatch('loadPDF', currentRoute.value.params.invoice).then(pdf => {
      state.pdf = pdf
    })

    return {
      state
    }
  }
}
</script>

<style lang="scss">
.invoice {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100vh;
    border: none;
  }
}
</style>
